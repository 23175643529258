<template>
	<div class="intro-test">
		<!-- <img class="navbar-fixed-ortho-test" src="@/assets/logos/logo-long-propre.png" /> -->
		<v-container class="main-container">
			<div class="div-full-intro-container-test-left">
				<v-row d-flex class="full-intro-container-test">
					<v-col lg="10" md="12" sm="12" cols="12">
						<TestIsDue v-if="testIsDue === true" :user="user" :testBank="testBank" :testScores="testScores" />
						<template v-else-if="testIsDue === false">
							<OutOfAttempt v-if="noMoreAttempts === true" :user="user" :testBank="testBank" :testScores="testScores" />
							<HaveAttempts
								v-else-if="noMoreAttempts === false"
								:user="user"
								:test="test"
								:testBank="testBank"
								:testScores="testScores"
							/>
						</template>
					</v-col>
				</v-row>
				<v-row v-if="testBank.caption_cover_photo" d-flex class="full-intro-container-test-right">
					<v-col lg="10" md="12" sm="12" cols="12">
						<img class="image-test-position-caption shadow-blue" :src="testBank.caption_cover_photo" />
					</v-col>
				</v-row>
			</div>
		</v-container>
		<img
			v-if="!testBank.caption_cover_photo"
			src="@/assets/images/img-presentation-story-big.png"
			class="image-test-position"
			alt=""
		/>
	</div>
</template>

<script>
import moment from 'moment';
import HaveAttempts from './HaveAttempts.vue';
import OutOfAttempt from './OutOfAttempt.vue';
import TestIsDue from './TestIsDue.vue';

export default {
	name: 'IntroTest',
	components: { HaveAttempts, OutOfAttempt, TestIsDue },
	props: {
		user: Object,
		test: Object,
		testBank: Object,
		testScores: Array,
	},
	data() {
		return {
			noMoreAttempts: null,
			testIsDue: null,
		};
	},
	methods: {
		checkTestIdDue() {
			if (this.test.due_date == null) {
				this.testIsDue = false;
				return false; // Test dont have due date
			}

			if (moment(this.test.due_date).isBefore(moment())) {
				this.testIsDue = true;
				return true;
			}

			this.testIsDue = false;
			return false;
		},
		checkStillHaveAttempts() {
			if (this.test.attempts == null) {
				this.noMoreAttempts = false; // Unlimited attempts
				return;
			}

			if (this.test.attempts && this.testScores) {
				if (this.testScores.length >= this.test.attempts) {
					this.noMoreAttempts = true;
				} else {
					this.noMoreAttempts = false;
				}
			}
		},
	},
	mounted() {
		const testIsDue = this.checkTestIdDue();
		if (testIsDue) return;
		this.checkStillHaveAttempts();
	},
};
</script>

<style scoped lang="scss">
.intro-test {
	background-color: #f8f8f8;
	width: 100%;
	height: 100%;
}

.main-container {
	height: 100%;
}

.div-full-intro-container-test-left {
	height: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.full-intro-container-test-right {
	margin-left: 20px;
	height: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.image-test-position {
	position: absolute;
	right: 0px;
	bottom: 0px;
	left: 62%;
	max-width: 27rem;
}

.image-test-position-caption {
	border-radius: 10px;
}

//for little resolution computer screen like laure bletton screen (jade)
@media only screen and (max-height: 600px) {
	.image-test-position {
		position: absolute;
		right: 0px;
		bottom: 0px;
		left: 65%;
		max-width: 18rem;
	}
}
//for mobile
@media only screen and (max-width: 1300px) {
	.image-test-position-container {
		right: -20%;
	}
}
@media only screen and (max-width: 960px) {
	.image-test-position {
		display: none;
	}
	.image-test-position-caption {
		display: none;
	}
	.full-intro-container-test-right {
		display: none;
	}
}
@media only screen and (max-width: 600px) {
	.image-test-position {
		display: none;
	}
	.intro-test {
		display: flex;
		flex-direction: row;
		align-items: center;
	}
}
</style>
