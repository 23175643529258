<template>
	<!-- FIRST TIME DO THE TEST -->
	<div v-if="firstTimeDoTest" class="text-test-position-container">
		<p class="test-name font-italic font-weight-light">{{ testBank.name }} - {{ testBank.level }}</p>
		<div class="title-test-position">
			<h1><span class="underlineText">Bonjour</span> {{ user.first_name }}</h1>
		</div>
		<div class="description-test-position">
			<div class="test-bullet">
				<p>
					- Réponds aux <strong> {{ testBank.total_questions }} questions suivantes</strong>
				</p>
				<p v-if="testBank.has_time_limit && testBank.time_limit_display">
					- Temps estimé : <strong>{{ testBank.time_limit_display }} ⌛</strong>
				</p>
			</div>
			<div v-if="testBank.instructions" class="test-desc" v-html="testBank.instructions"></div>
			<div class="alert-block">
				<span v-if="test.attempts == null">
					🚨 Il n'y a pas de limite de tentatives, tu pourras le refaire autant de fois que tu le souhaites pour
					améliorer ton score.<br />
				</span>
				<span v-else-if="test.attempts == 1">
					🚨 Tu ne peux pas refaire ce test à partir du moment où tu cliques sur “C’est parti”.<br />
					🚨 Attention, actualiser la page ou revenir en arrière compte comme une tentative.<br />
				</span>
				<span v-else>
					🚨 Tu as la possibilité de refaire ce test <strong>{{ test.attempts }} fois</strong>.<br />
					🚨 Attention, actualiser la page ou revenir en arrière compte comme une tentative.<br />
				</span>
			</div>
		</div>
		<div class="hvr-grow ortho-next-button" @click="startTest">
			C'est parti
			<v-icon class="ortho-icon-white fs15 ml5">fas fa-arrow-right</v-icon>
		</div>
	</div>

	<!-- NOT FIRST TIME DO THE TEST -->
	<div v-else-if="firstTimeDoTest === false" class="text-test-position-container">
		<!-- TEST IS UNLIMITED ATTEMPTS -->
		<template v-if="unlimitedAttempts === true">
			<p class="test-name font-italic font-weight-light">{{ testBank.name }} - {{ testBank.level }}</p>
			<div class="title-test-position">
				<h1>{{ user.first_name }}, bats ton <span class="underlineText">précédent score !</span></h1>
			</div>
			<br />
			<div class="content-block">
				<div class="text-box">Ton meilleur score : {{ getHighestTestScore() }}%</div>
			</div>
			<br />

			<div class="description-test-position">
				<p>
					- Réponds aux <strong> {{ testBank.total_questions }} questions suivantes</strong>
				</p>
				<p v-if="testBank.has_time_limit && testBank.time_limit_display">
					- Temps estimé : <strong>{{ testBank.time_limit_display }} ⌛</strong>
				</p>
				<br />
			</div>
			<div class="hvr-grow ortho-next-button" @click="startTest">
				Relève le défi
				<v-icon class="ortho-icon-white fs15 ml5">fas fa-arrow-right</v-icon>
			</div>
		</template>
		<!-- TEST IS LIMITED ATTEMPTS -->
		<template v-else>
			<p class="test-name font-italic font-weight-light">{{ testBank.name }} - {{ testBank.level }}</p>
			<div class="title-test-position">
				<h1>{{ user.first_name }}, bats ton <span class="underlineText">précédent score !</span></h1>
			</div>
			<br />
			<div class="content-block">
				<div class="text-box">Ton meilleur score : {{ getHighestTestScore() }}%</div>
			</div>
			<br />

			<div class="description-test-position">
				<p>
					- Réponds aux <strong> {{ testBank.total_questions }} questions suivantes</strong>
				</p>
				<p v-if="testBank.has_time_limit && testBank.time_limit_display">
					- Temps estimé : <strong>{{ testBank.time_limit_display }} ⌛</strong>
				</p>
				<br />
			</div>
			<div class="alert-block">
				<span>
					🚨 Il te reste encore
					<strong>{{ remainingAttempts }} {{ remainingAttempts > 1 ? 'tentatives' : 'tentative' }}</strong
					>.<br />
					🚨 Attention, actualiser la page ou revenir en arrière compte comme une tentative.<br />
				</span>
			</div>
			<br />
			<div class="hvr-grow ortho-next-button" @click="startTest">
				Relève le défi
				<v-icon class="ortho-icon-white fs15 ml5">fas fa-arrow-right</v-icon>
			</div>
		</template>
	</div>
</template>

<script>
export default {
	name: 'HaveAttempts',
	props: {
		user: Object,
		test: Object,
		testBank: Object,
		testScores: Array,
	},
	inject: ['startTest'],
	computed: {
		unlimitedAttempts() {
			return this.test.attempts == null;
		},
		firstTimeDoTest() {
			return this.testScores.length === 0;
		},
		remainingAttempts() {
			return this.test.attempts - this.testScores.length;
		},
	},
	methods: {
		getHighestTestScore() {
			if (!this.testScores || this.testScores.length === 0) return;
			const cloneTestScores = [...this.testScores];
			cloneTestScores.sort((a, b) => b.score - a.score);
			return cloneTestScores[0].score;
		},
	},
};
</script>

<style scoped lang="scss">
.test-name {
	font-size: 15px;
}
.description-test-position {
	margin-top: 20px;
	font-size: 20px;
}
.ortho-next-button {
	margin-bottom: 12%;
}

.content-block {
	margin-top: 20px;
	font-size: 20px;
}

.title-test-position {
	font-weight: 900;
	line-height: 1.1;
	font-size: 35px;
}

.text-test-position-container {
	text-align: left;
}

.test-bullet {
	margin-bottom: 30px;
	font-size: 18px;
}

.test-desc {
	font-size: 15px;
	background-color: white;
	padding: 10px 10px 10px 10px;
	border-radius: 5px;
	margin-bottom: 30px;
	text-align: left;
}

.alert-block {
	line-height: 18px;
	span {
		font-size: 15px;
	}
	.alert-icon {
		font-size: 15px;
	}
}

.text-box {
	background-color: white;
	padding: 5px 10px;
	width: fit-content;
	border-radius: 5px;
	margin-top: 20px;
	margin-bottom: 10px;
}

@media only screen and (max-width: 1300px) {
	.image-test-position-container {
		right: -20%;
	}
}

@media only screen and (max-width: 600px) {
	.description-test-position {
		margin-top: 20px;
		font-size: 18px;
	}
	.content-block {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.full-container {
		position: relative;
	}
	.text-test-position-container {
		text-align: center;
		padding-left: 0px;
		padding-right: 0px;
	}
	.title-test-position {
		font-size: 20px;
	}
	.image-test-position {
		display: none;
	}
}
</style>
