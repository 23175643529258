<template>
	<div class="text-test-position-container">
		<p class="test-name font-italic font-weight-light">{{ testBank.name }} - {{ testBank.level }}</p>
		<div class="title-test-position">
			<h1>Bonjour {{ user.first_name }}, ce test <span class="underlineText">n’est plus disponible</span></h1>
		</div>
		<div class="content-block">
			<br />
			<div class="alert-block">
				<span>🚨 La date d’échéance du test est désormais passée</span>
			</div>
		</div>
		<div class="ortho-next-button">Plus disponible</div>
	</div>
</template>

<script>
export default {
	name: 'TestIsDue',
	props: {
		user: Object,
		testBank: Object,
		testScores: Array,
	},
};
</script>

<style scoped lang="scss">
.test-name {
	font-size: 18px;
}
.content-block {
	margin-top: 20px;
	font-size: 20px;
}
.ortho-next-button {
	margin-bottom: 12%;
	background-color: #dddddd;
	border-color: #dddddd;
	cursor: default;
}

.title-test-position {
	font-weight: 900;
	line-height: 1.1;
	font-size: 35px;
}

.text-test-position-container {
	text-align: left;
}

.alert-block {
	line-height: 18px;
	span {
		font-size: 15px;
	}
	.alert-icon {
		font-size: 15px;
	}
}

@media only screen and (max-width: 1300px) {
	.image-test-position-container {
		right: -20%;
	}
}

@media only screen and (max-width: 600px) {
	.content-block {
		margin-top: 20px;
		font-size: 18px;

		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.full-container {
		position: relative;
	}
	.text-test-position-container {
		text-align: center;
		padding-left: 0px;
		padding-right: 0px;
	}
	.title-test-position {
		font-size: 20px;
	}
	.image-test-position {
		display: none;
	}
}
</style>
