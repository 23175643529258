<template>
	<div class="h-100">
		<div v-if="user && test && testBank && testScores" class="h-100">
			<IntroTest v-if="step === 'intro-test'" :user="user" :testBank="testBank" :testScores="testScores" :test="test" />
			<InTest v-else-if="step === 'in-test'" :testBank="testBank" :testTracks="testTracks" />
		</div>
		<Loading :show="loading" label="3, 2, 1..." />
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import Loading from 'vue-full-loading';
import IntroTest from '@/components/intro-test/IntroTest.vue';
import InTest from '@/components/in-test/InTest.vue';

export default {
	name: 'DoTestPage',
	components: {
		IntroTest,
		InTest,
		Loading,
	},
	computed: {
		...mapGetters('accountManager', { isAuthenticated: 'isAuthenticated' }),
	},
	data() {
		return {
			loading: true,
			user: null,
			test: null,
			testBank: null,
			testScores: null, // Test score of all attempts
			testTracks: null,
			step: 'intro-test', // step intro test > step in test
		};
	},
	methods: {
		async createTestScore() {
			const lastAttempt =
				this.testScores.length === 0 ? 0 : this.testScores.sort((a, b) => b.attempt - a.attempt)[0].attempt;

			if (this.test.attempts == null || lastAttempt + 1 <= this.test.attempts) {
				await this.$store.dispatch('testScores/createTestScore', {
					testId: this.test._id,
					userId: this.user._id,
					testBankId: this.testBank._id,
					attempt: lastAttempt + 1,
				});
			}
		},
		async startTest() {
			this.loading = true;
			this.testTracks = await this.$store.dispatch('tests/fetchAndSetTestTracks', { testBankId: this.testBank._id });
			await this.createTestScore();
			setTimeout(() => {
				this.loading = false;
				this.step = 'in-test';
			}, 2000);
		},
		async checkUserIsInTest(testId, userId) {
			const result = await this.$store.dispatch('tests/checkUserIsInTest', { testId, userId });
			return result;
		},
		async checkUserHasChangePassword(user) {
			if (user == null) return false;
			if (user.has_changed_password !== true) {
				this.$router.push({
					name: 'ResetPassword',
					params: { idUser: user._id },
					query: { onSuccessRedirectTo: this.$route.fullPath },
				});
				return false;
			}
			return true;
		},
		async fetchAndSetTest(testId, userId) {
			const { test, testBank } = await this.$store.dispatch('tests/fetchAndSetTest', { testId, userId });
			this.test = test;
			this.testBank = testBank;
		},
		async fetchAndSetTestScore(testId, userId) {
			this.testScores = await this.$store.dispatch('testScores/fetchTestScores', { testId, userId });
		},
		async checkConditionForTest() {
			const { userId, testId } = this.$route.params;

			const isUserInTest = await this.checkUserIsInTest(testId, userId);
			if (isUserInTest === false) {
				this.$router.push(''); // User not belong to the test, back to dashboard
				return;
			}

			const _user = await this.$store.dispatch('accountManager/getUserWihoutFormation', { idUser: userId });

			this.user = _user;

			const checkChangedPassword = await this.checkUserHasChangePassword(_user);
			if (checkChangedPassword == false) return;

			if (this.isAuthenticated == false) {
				this.$router.push({ name: 'Login' });
				return;
			}
			await this.fetchAndSetTest(testId, userId);
			await this.fetchAndSetTestScore(testId, userId);
		},
	},
	async beforeMount() {
		await this.checkConditionForTest();
		this.loading = false;
	},
	mounted() {
		// Change navbar bg color
		const navEle = document.querySelector('header.navbar');
		if (navEle == null) return;
		navEle.style.setProperty('background-color', '#f8f8f8', 'important');
	},
	beforeDestroy() {
		this.$store.dispatch('tests/clear');
		this.$store.dispatch('testScores/clear');

		// Change navbar bg color back to white
		const navEle = document.querySelector('header.navbar');
		if (navEle == null) return;
		navEle.style.setProperty('background-color', 'white', 'important');
	},
	provide() {
		return {
			startTest: this.startTest,
		};
	},
};
</script>

<style scoped lang="scss">
.h-100 {
	height: 100%;
}
</style>
