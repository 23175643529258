<template>
	<div class="ortho-results-container">
		<div>
			<v-card
				class="mx-auto shadow-blue"
				max-width="600"
				:class="{
					pa7: $vuetify.breakpoint.smAndDown,
					pa60: $vuetify.breakpoint.mdAndUp,
				}"
			>
				<div class="mb20">
					<img src="@/assets/icons/check.svg" class="mt20 check-icon-results" />
				</div>
				<div class="mb20">
					<div class="ortho-results-score-title ortho-bold">
						<span>Félicitations, test terminé !</span>
					</div>
				</div>
				<div class="mb20 mt20">
					<div class="ortho-results-text mb20 pr-10 pl-10">
						<span>Sauvegarde de tes résultats en cours...</span>
					</div>
				</div>
				<v-btn color="black" dark large :loading="loading" @click="toUserTestReport()" class="hvr-shrink mt20 mb20">
					Mon rapport personnel
					<v-icon class="ortho-icon-white fs15 ml5">fas fa-arrow-right</v-icon>
				</v-btn>
			</v-card>
		</div>
	</div>
</template>

<script>
import { decideTestStatus } from '@/utils/test.js';

export default {
	name: 'FinishTestAlert',
	props: {
		testScoreId: String,
	},
	data() {
		return { loading: true };
	},
	mounted: function () {
		const { userId } = this.$route.params;
		if (userId != null) this.fetchAndSetAllUserTests(userId);

		setTimeout(() => {
			this.loading = false;
		}, 5000);
	},
	methods: {
		toUserTestReport() {
			this.$router.push({
				name: 'TestResultPage',
				params: { testScoreId: this.testScoreId },
			});
		},
		findTestScoresByTestId(testScores, testId) {
			if (testId == null) return null;
			if (testScores == null) return null;
			return testScores.filter((testScore) => testScore.test_id === testId);
		},
		async fetchAndSetAllUserTests(userId) {
			if (userId == null) return;
			// Fetch all user tests and testScores to update each test status
			let _userTests = await this.$store.dispatch('tests/fetchAllUserTests', {
				userId: userId,
			});
			_userTests = _userTests.filter((test) => test.canceled_at == null);

			const testScores = await this.$store.dispatch('testScores/fetchTestScores', {
				userId: userId,
			});

			// Find status for _userTests
			_userTests.forEach((test) => {
				const _testScores = this.findTestScoresByTestId(testScores, test._id);
				const statusOfTheTest = decideTestStatus(test, _testScores);
				test.status = statusOfTheTest;
			});

			this.$store.dispatch('tests/setAllTestsOfUserAction', _userTests);
		},
	},
};
</script>
