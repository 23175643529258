/**
 *
 * @param {TestTrack} testTrack
 * @param {Array<Object> | null} userAnswers
 * @returns track score is 0% - 100%
 */
const calculateQCMTrackScore = (testTrack, userAnswers) => {
	if (userAnswers == null) return 0;
	if (userAnswers[0] == null) return 0;

	const totalCorrectAnswer = testTrack.answers.filter((answer) => answer.is_good_answer === true).length;
	const userCorrectAnswer = userAnswers.filter((answer) => answer.is_good_answer === true).length;

	const score = (userCorrectAnswer / totalCorrectAnswer) * 100;
	return Math.round(score * 100) / 100;
};

/**
 *
 * @param {Array<Object>} userAnswers array of testScore.user_answers
 */
const calculateTestScore = (userAnswers) => {
	let totalCoef = 0;
	let totalTrackScoreTimesCoef = 0;
	userAnswers.forEach((userAnswer) => {
		totalCoef += userAnswer.coefficient;
		totalTrackScoreTimesCoef += userAnswer.score * userAnswer.coefficient;
	});
	const testScore = Math.ceil(totalTrackScoreTimesCoef / totalCoef); // % Percentage
	return testScore;
};

/**
 *
 * @param {TestTrack | null} nextTestTrack
 */
export const changeStatusTestScore = (nextTestTrack) => {
	return nextTestTrack == null ? 'completed' : 'doing';
};

/**
 * @param {TestTrack | null} testTrack
 * @param {Array<Object> | null} answers null when time out user didnt select any anwser
 */
export const processTestScore = (testScore, testTrack, answers, secondsSpentOnTrack) => {
	// Calculate score for test track
	if (testTrack.question_type === 'QCM') {
		const userAnwser = {
			test_track_id: testTrack._id,
			score: calculateQCMTrackScore(testTrack, answers),
			question_type: testTrack.question_type,
			user_answers: answers,
			coefficient: testTrack.coefficient,
		};
		testScore.user_answers.push(userAnwser);
	}
	// else if other question type ....

	const timeSpentObj = {
		test_track_id: testTrack._id,
		seconds_spent: secondsSpentOnTrack,
	};
	testScore.duration_per_track.push(timeSpentObj);

	testScore.score = calculateTestScore(testScore.user_answers);
	return testScore;
};
