<template>
	<v-container v-if="testTracks" class="in-test-container">
		<img class="navbar-fixed-ortho-test" src="@/assets/logos/logo-long-propre.png" />
		<v-row class="mb-6" justify="center" no-gutters v-if="count < testTracks.length">
			<v-progress-circular
				v-if="applyLimitAnswerTime"
				class="chrono"
				:rotate="-90"
				:width="5"
				:size="70"
				:value="
					(100 * timeBeforeNextQuestion) /
					(testTracks[count].suggest_answer_time ? testTracks[count].suggest_answer_time : 30)
				"
				:color="changeColor()"
			>
				{{ timeBeforeNextQuestion }}
			</v-progress-circular>

			<v-col class="test-position-container" xl="6" md="12" sm="12" cols="12">
				<div class="ortho-bold test-position-question">
					<span>{{ testTracks[count].question }}</span>
				</div>
				<div v-if="testTracks[count] && testTracks[count].instruction" class="test-position-action-container">
					<p>{{ testTracks[count].instruction }}</p>
				</div>
				<div class="test-position-answers-container">
					<FadeTransition group>
						<div
							class="hvr-bounce-in test-position-answers"
							v-for="(answer, index) in testTracks[count].answers"
							:key="index"
							@click="onClickAnswer(answer)"
						>
							{{ answer.answer }}<br />
						</div>
					</FadeTransition>
				</div>
				<div class="test-position-sectionprogress-container">
					<p>{{ count + 1 }}/{{ testTracks.length }}</p>
				</div>
			</v-col>
			<div v-if="showModalExit" class="modal-overlay">
				<div class="modal">
					<h2 class="mb-3">Attention</h2>
					<p>
						Si vous quittez cette page, votre test sera réinitialisé et comptera comme une tentative. Voulez-vous
						vraiment continuer ?
					</p>
					<div class="modal-buttons">
						<button @click="confirmerQuitter">Oui, sortir</button>
						<button @click="closeAllModal">Non, continuer le test</button>
					</div>
				</div>
			</div>
			<div v-if="showModalTriche" class="modal-overlay">
				<div class="modal">
					<h2 class="mb-3">🚨 Tentative de triche détéctée</h2>
					<p class="mb-5">
						Nous avons remarqué que tu as changé d'onglet, ce qui peut être interprété comme une tentative de triche.
					</p>
					<p class="alert-message">Dernier avertissement : ton passage sera annulé si cela se reproduit.</p>

					<div class="modal-buttons">
						<button @click="closeAllModal">J'ai compris, je me concentre</button>
					</div>
				</div>
			</div>
		</v-row>
		<FinishTestAlert v-if="testTracks.length > 0 && count === testTracks.length" :testScoreId="testScore._id" />
	</v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import { FadeTransition } from 'vue2-transitions';
import { processTestScore, changeStatusTestScore } from '@/core/testScore.js';
import FinishTestAlert from './FinishTestAlert.vue';

const DEFAULT_QCM_ANSWER_TIME = 30; // seconds

export default {
	name: 'InTest',
	props: {
		testBank: Object,
		testTracks: Array,
	},
	components: { FinishTestAlert, FadeTransition },
	data() {
		return {
			count: 0,
			timeBeforeNextQuestion: null,
			questionInterval: null,
			clockInQuestionTime: Date.now(), // Unix timestamp in milliseconds
			isBackNavigation: false,
			showModalExit: false,
			showModalTriche: false,
		};
	},
	computed: {
		...mapGetters('testScores', { testScore: 'testScore' }),
		applyLimitAnswerTime() {
			return !!this.testBank.has_time_limit;
		},
	},
	mounted() {
		document.addEventListener('copy', (event) => {
			event.preventDefault();
			alert('La copie est désactivée sur cette page.');
		});
	},
	created() {
		// Hide nav bar
		const navEle = document.querySelector('header.navbar');
		if (navEle == null) return;
		navEle.classList.add('d-none');

		if (this.applyLimitAnswerTime) {
			this.setTimeBeforeNextQuestion();
			this.setIntervalTime();
		}
		if (process.env.VUE_APP_MIXPANEL == 'production') {
			this.$mixpanel.track('testPositionnement-questions');
		}
		window.addEventListener('beforeunload', this.handleBeforeUnload);

		this.blockBackNavigation();

		document.addEventListener('visibilitychange', () => {
			if (document.hidden) {
				this.showModalTriche = true;
			}
		});
	},
	beforeDestroy() {
		window.removeEventListener('beforeunload', this.handleBeforeUnload);
		window.removeEventListener('popstate', this.handlePopState);
	},
	destroyed() {
		// Unhide nav bar
		const navEle = document.querySelector('header.navbar');
		if (navEle == null) return;
		navEle.classList.remove('d-none');

		if (this.questionInterval) clearInterval(this.questionInterval);
	},
	methods: {
		changeColor() {
			if (this.timeBeforeNextQuestion >= 15) return '#15c39a';
			else if (this.timeBeforeNextQuestion >= 5) return 'orange';
			else return '#F95F62';
		},
		setIntervalTime() {
			this.questionInterval = setInterval(this.onAnswerTimeOut, 1000);
		},
		setTimeBeforeNextQuestion() {
			if (this.applyLimitAnswerTime === false) return;
			if (this.testTracks[this.count] && this.testTracks[this.count].question_type === 'QCM') {
				if (this.testTracks[this.count].suggest_answer_time > 0)
					this.timeBeforeNextQuestion = this.testTracks[this.count].suggest_answer_time;
				else {
					this.timeBeforeNextQuestion = DEFAULT_QCM_ANSWER_TIME;
				}
			}
		},
		resetTimer() {
			clearInterval(this.questionInterval);
			this.setIntervalTime();
			this.setTimeBeforeNextQuestion();
		},
		setClockInQuestionTime() {
			this.clockInQuestionTime = Date.now();
		},
		async processAnswer(answer) {
			const secondsSpendOnThisQuestion = moment().diff(moment(this.clockInQuestionTime), 'seconds');
			const toUpdateTestScore = await processTestScore(
				this.testScore,
				this.testTracks[this.count],
				[answer],
				secondsSpendOnThisQuestion
			);
			toUpdateTestScore.status = changeStatusTestScore(this.testTracks[this.count + 1]);

			await Promise.all([
				this.$store.dispatch('testScores/updateTestScore', { toUpdateTestScore }),
				this.$store.dispatch('streak/submitDailyStreakByUserId', { userId: toUpdateTestScore.user_id }),
			]);
		},
		async onAnswerTimeOut() {
			const answer = null; // User didnt choose any answer
			if (this.count === this.testTracks.length) {
				clearInterval(this.questionInterval); //if last question not clicked
			} else if (this.timeBeforeNextQuestion === 1) {
				await this.processAnswer(answer);
				this.count++;
				this.resetTimer();
				this.setClockInQuestionTime();
			} else {
				this.timeBeforeNextQuestion--;
			}
		},

		async onClickAnswer(answer) {
			await this.processAnswer(answer);
			this.count++;
			if (this.count === this.testTracks.length) clearInterval(this.questionInterval);
			else {
				this.resetTimer();
				this.setClockInQuestionTime();
			}
		},
		handleBeforeUnload(event) {
			// Définir un message d'avertissement personnalisé
			const warningMessage =
				'Êtes-vous sûr de vouloir quitter cette page ? Les données non sauvegardées seront perdues.';
			event.preventDefault();
			event.returnValue = warningMessage; // Nécessaire pour certains navigateurs (notamment Chrome)
			return warningMessage; // Utile pour la compatibilité
		},

		blockBackNavigation() {
			// Ajoute un nouvel état à l'historique pour empêcher le retour
			window.history.pushState(null, '', window.location.href);
			window.addEventListener('popstate', this.handlePopState);
		},

		handlePopState() {
			// Réinjecte l'état courant pour bloquer le retour
			window.history.pushState(null, '', window.location.href);
			this.showModalExit = true;
		},

		confirmerQuitter() {
			this.showModalExit = false;
			location.reload();
		},
		closeAllModal() {
			this.showModalExit = false;
			this.showModalTriche = false;
		},
	},
};
</script>

<style lang="scss" scoped>
.in-test-container {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.scale-move {
	transition: transform 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.chrono {
	position: absolute;
	top: 0;
	right: 0;
	margin-right: 20px;
	margin-top: 10px;
	max-width: 60px;
	font-weight: bold;
}
.test-position-container {
	margin-top: 130px;
}
.test-position-question {
	font-size: 50px;
	text-align: center;
}
.test-position-action-container {
	text-align: center;
	padding-top: 30px;
	font-size: 20px;
	color: grey;
}
.test-position-answers-container {
	text-align: center;
	padding-top: 5%;
	font-size: 20px;
	margin-left: 150px;
	margin-right: 150px;
}
.test-position-sectionprogress-container {
	text-align: center;
	padding-top: 10%;
}
.test-position-answers {
	display: inline-block;
	border-radius: 25px;
	margin: 15px;
	padding: 10px 25px;
	min-width: 10px;
	text-align: center;
	font-family: 'Lato';
	cursor: pointer;
	border: grey 2px solid;
	color: #000000;
	font-weight: bold;
}
/* Bounce In */
.hvr-bounce-in {
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	-webkit-transition-duration: 0.5s;
	transition-duration: 0.3s;
	&:hover {
		background-color: #6afdef;
		border-color: #6afdef;
		color: black;
		font-weight: bold;
		-webkit-transform: scale(1.2);
		transform: scale(1.2);
	}
}

.modal-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
}

.modal {
	background: white;
	padding: 20px;
	border-radius: 8px;
	text-align: center;
	max-width: 400px;
	width: 100%;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
}

.modal-buttons button {
	margin: 5px;
	padding: 10px 20px;
	border: none;
	border-radius: 4px;
	cursor: pointer;
}

.modal-buttons button:first-child {
	background-color: #e74c3c;
	color: white;
}

.modal-buttons button:last-child {
	background-color: #3498db;
	color: white;
}
.alert-message {
	color: red;
	font-weight: bold;
}

@media only screen and (max-width: 600px) {
	.modal {
		background: white;
		padding: 20px;
		border-radius: 8px;
		text-align: center;
		max-width: 100%;
		width: 80%;
		box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
	}
	.chrono {
		top: 0;
		right: 0;
		margin-top: 0px;
		margin-right: 15px;
		position: absolute;
		max-width: 50px;
	}
	.test-position-container {
		margin-top: 90px;
	}
	.test-position-question {
		padding-left: 10px;
		padding-right: 10px;
		padding-top: 30px;
		font-size: 30px;
	}
	.test-position-action-container {
		padding-top: 50px;
		padding-left: 30px;
		padding-right: 30px;
		font-size: 17px;
	}
	.test-position-answers-container {
		margin-left: 20px;
		margin-right: 20px;
	}
	/* removing hover effect on mobile */
	.hvr-bounce-in:hover,
	.hvr-bounce-in:focus,
	.hvr-bounce-in:active {
		background-color: initial;
		border-color: grey;
		color: initial;
		font-weight: bold;
		-webkit-transform: initial;
		transform: initial;
	}
}
</style>
